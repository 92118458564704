import React from 'react';
import '../css/Loading.css';

const Loading = () => {
  return (
    <div 
      className="loading-container" 
      role="status" 
      aria-live="polite" 
      aria-label="Loading content, please wait"
    >
      <div className="spinner" aria-hidden="true"></div>
      <p>Loading...</p>
    </div>
  );
};

export default Loading;