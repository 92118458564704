import React, { useState, useEffect } from "react";
import "../css/AdminDashboard.css";
import { useNavigate } from "react-router-dom";
import { ResponsivePie } from "@nivo/pie";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { fetchAnalytics } from "../services/authService";
import { useGeneralMsgUpdate } from "../context/GenralMsgContext";

// Register Chart.js components
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const AdminDashboard = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [userGrowthView, setUserGrowthView] = useState("daily");
  const [notesCreatedView, setNotesCreatedView] = useState("daily");
  const setGeneralMsg = useGeneralMsgUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    const getAnalytics = async () => {
      try {
        const data = await fetchAnalytics();

        if (data) {
          setAnalyticsData(data);
        } else {
          setGeneralMsg("No analytics data found", "error");
          navigate("/");
        }
      } catch (err) {
        setGeneralMsg(err.message || "An error occurred while fetching analytics data.", "error");
        navigate("/");
      }
    };

    getAnalytics();
  }, []);

  // Check if analyticsData is null or undefined before destructuring
  if (!analyticsData) {
    return <div>Loading...</div>; // You can show a loading state or spinner here
  }

  // Destructure with fallback values
  const {
    totalUsers = "_",
    verifiedUsers = "_",
    totalNotes = "_",
    userGrowth = {},
    notesCreated = {},
    engagementStats = [],
    newUsersToday = 0,
    verifiedUsersToday = 0,
    newNotesToday = 0,
  } = analyticsData;

  // Line Chart Data for User Growth
  const userGrowthData = {
    labels: userGrowth[userGrowthView] ? userGrowth[userGrowthView].map((item) => item._id) : [],
    datasets: [
      {
        label: "Users",
        data: userGrowth[userGrowthView] ? userGrowth[userGrowthView].map((item) => item.count) : [],
        borderColor: "#0A66C2",
        backgroundColor: "rgba(10, 102, 194, 0.2)",
        fill: true,
      },
    ],
  };

  // Line Chart Data for Notes Created
  const notesCreatedData = {
    labels: notesCreated[notesCreatedView] ? notesCreated[notesCreatedView].map((item) => item._id) : [],
    datasets: [
      {
        label: "Notes",
        data: notesCreated[notesCreatedView] ? notesCreated[notesCreatedView].map((item) => item.count) : [],
        borderColor: "#0A66C2",
        backgroundColor: "rgba(10, 102, 194, 0.2)",
        fill: true,
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Admin Analytics Dashboard</h1>

      {/* Metrics Section */}
      <div className="metrics-container">
        <div className="metric-card">
          <h2>New Users Today</h2>
          <p>
            {newUsersToday || "_"} (<span className="highlight">{verifiedUsersToday || "_"}</span>)
          </p>
        </div>
        <div className="metric-card">
          <h2>Total Users</h2>
          <p>
            {totalUsers || "_"} (<span className="highlight">{verifiedUsers || "_"}</span>)
          </p>
        </div>
        <div className="metric-card">
          <h2>New Notes Today</h2>
          <p>{newNotesToday || "_"}</p>
        </div>
        <div className="metric-card">
          <h2>Total Notes</h2>
          <p>{totalNotes || "_"}</p>
        </div>
      </div>

      {/* User Growth Section */}
      <div className="graphs">
        <div className="chart-section user-growth">
          <h3>User Growth</h3>
          <select
            onChange={(e) => setUserGrowthView(e.target.value)}
            value={userGrowthView}
            className="chart-select"
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
          {userGrowth[userGrowthView] && userGrowth[userGrowthView].length > 0 ? (
            <Line data={userGrowthData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
          ) : (
            <div>No data available</div>
          )}
        </div>

        {/* Notes Created Section */}
        <div className="chart-section notes-created">
          <h3>Notes Created</h3>
          <select
            onChange={(e) => setNotesCreatedView(e.target.value)}
            value={notesCreatedView}
            className="chart-select"
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
          {notesCreated[notesCreatedView] && notesCreated[notesCreatedView].length > 0 ? (
            <Line data={notesCreatedData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>

      {/* Engagement Stats Section */}
      <div className="chart-section">
        <h3>User Engagement</h3>
        {engagementStats.length > 0 ? (
          <div className="engagement-pie">
            <ResponsivePie
              data={engagementStats}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              colors={{ scheme: "blues" }}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              radialLabelsSkipAngle={10}
              radialLabelsTextColor="#ffffff"
              radialLabelsLinkColor={{ from: "color" }}
              sliceLabelsSkipAngle={10}
              sliceLabelsTextColor="#ffffff"
            />
          </div>
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;