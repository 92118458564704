import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../services/authService';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {FcGoogle} from 'react-icons/fc';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import '../css/Auth.css';
import { Helmet } from 'react-helmet';

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const setGeneralMsg = useGeneralMsgUpdate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if(errors[name]){
      setErrors({
        ...errors,
        [name]: undefined
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const { firstName, lastName, email, password, confirmPassword } = formData;

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!password) {
      newErrors.password = 'Password is required';
    } else {
      const errors = [];
      if (password.length < 8) errors.push('be at least 8 characters long');
      if (!/[A-Z]/.test(password)) errors.push('have at least one uppercase letter');
      if (!/[a-z]/.test(password)) errors.push('have at least one lowercase letter');

      if (errors.length > 0) {
        if (errors.length > 1) {
          const lastError = errors.pop();
          newErrors.password = `Password must ${errors.join(', ')} and ${lastError}.`;
        } else {
          newErrors.password = `Password must ${errors[0]}.`;
        }
      }

    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      try {
        await register(formData.firstName, formData.lastName, formData.email, formData.password);
        setGeneralMsg('Please check your email to verify your account.', 'success');
        navigate('/login');
      } catch (error) {
        const errorMsg = error? error : 'An unknown error occurred';
        setGeneralMsg(errorMsg, 'error');  
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
      <title>Sign Up - LinknTrack</title>

      <meta name="description" content="Create an account on LinknTrack to start tracking and personalizing your LinkedIn connections." />
      <meta name="keywords" content="Sign Up, LinknTrack, create account, LinkedIn, Chrome extension" />
      <meta property="og:title" content="Sign Up - LinknTrack" />
      <meta property="og:description" content="Join LinknTrack to personalize and manage your LinkedIn connections with ease." />
      <meta property="og:url" content="https://linkntrack.com/signup" />

      <meta name="twitter:title" content="Sign Up - LinknTrack" />
      <meta name="twitter:description" content="Join LinknTrack to personalize and manage your LinkedIn connections with ease." />
      <meta name="twitter:url" content="https://linkntrack.com/signup" />
    </Helmet>

    <div className='signup-page'>
      <div className='signup-title'>
        <h1>Create an <span>Account</span></h1>
        <p className='description'>Sign up to start adding notes to your LinkedIn connections.</p>
      </div>
      <div className="signup-form-container">
        <form onSubmit={handleSubmit} className='signup-form'>
            <div className='name-container'> 
            <input
              type="text"
              name="firstName"
              placeholder='First Name'
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
            <input
              type="text"
              name="lastName"
              placeholder='Last Name'
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <span className="error">{errors.lastName}</span>}
            </div>
            
            <input
              type="email"
              name="email"
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}

            <div className="password-input-container">
              <input
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setPasswordVisible((prevState) => !prevState)}
                aria-label="Toggle password visibility"
              >
                {!passwordVisible ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            {errors.password && <span className="error">{errors.password}</span>}

            <div className="password-input-container">
              <input
                type={confirmPasswordVisible ? 'text' : 'password'}
                name="confirmPassword"
                placeholder='Confirm Password'
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setConfirmPasswordVisible((prevState) => !prevState)}
                aria-label="Toggle confirm password visibility"
              >
                {!confirmPasswordVisible ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}

          <button className='signup-btn' type="submit" disabled={isLoading}>
            {isLoading ? 'Signing up...' : 'Sign Up'}
          </button>
          <div className='or'>or</div>
          {/* sign in with google */}
          <a href={`${process.env.REACT_APP_BACKEND_API_URL}/auth/google`} aria-label={`Sign in with Google`} className='google-signin-btn signup-btn'> <FcGoogle />Sign in with Google</a>
          <p>Already have an account? <Link to="/login" className='color-blue underline'>Login</Link></p>
        </form>
      </div>
    </div>
    
    </>
  );
};

export default Signup;