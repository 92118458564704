import React, { useState, useEffect } from 'react';
import '../css/GenralMsg.css';
import { FaRegWindowClose } from 'react-icons/fa';

const GeneralMsg = ({ message, type, handleExit }) => {
    const [progress, setProgress] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const timeoutDuration = 5000; // 5 seconds

    useEffect(() => {
        if (isPaused) return;

        // Calculate progress and set timeout
        const progressInterval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(progressInterval);
                    return 100;
                }
                return prev + (100 / (timeoutDuration / 100));
            });
        }, 100);

        const timeout = setTimeout(() => {
            handleExit();
        }, timeoutDuration);

        // Cleanup on unmount or when message changes
        return () => {
            clearInterval(progressInterval);
            clearTimeout(timeout);
        };
    }, [message, handleExit, isPaused]);

    return (
        <div 
            className={`general-msg-container ${type}`}
            role="alert"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
            aria-live={type === 'error' ? 'assertive' : 'polite'}
        >
            <div className='general-msg'>
                {message}
            </div>

            <button onClick={handleExit} aria-label="Close message">
                <FaRegWindowClose />
            </button>

            <div className='progress-bar'
                aria-label="Progress bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow={progress}
            >
                <div className='progress' style={{ width: `${progress}%` }} />
            </div>
        </div>
    );
};

export default GeneralMsg;