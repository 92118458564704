import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import '../css/Profile.css';
import '../css/Auth.css';
import { Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import { changePassword } from '../services/authService';

const PasswordInput = ({ value, onChange, visible, toggleVisibility, placeholder }) => (
  <div className="password-input-container">
    <input
      type={visible ? 'text' : 'password'}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required
    />
    <button
      type="button"
      className="password-toggle"
      onClick={toggleVisibility}
      aria-label="Toggle password visibility"
    >
      {visible ? <FiEye /> : <FiEyeOff />}
    </button>
  </div>
);

const Profile = () => {
  const { user } = useAuth();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isSendingNewPass, setIsSendingNewPass] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const setGeneralMsg = useGeneralMsgUpdate();
    const validateInputs = () => {

      if (newPassword.length < 8) return 'Password must be at least 8 characters long';
      if (!/[A-Z]/.test(newPassword)) return 'Password must have at least one uppercase letter';
      if (!/[a-z]/.test(newPassword)) return 'Password must have at least one lowercase letter';

    if (newPassword !== confirmPassword) {
      return 'Passwords do not match';
    }

    return null;

  };

  const handleChangePass = async (e) => {
    e.preventDefault();
    setIsSendingNewPass(true);
    try {
      const error = validateInputs();

      if (error) {
        setGeneralMsg(error, 'error');
        return;
      }

      await changePassword(oldPassword, newPassword);

      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowChangePassword(false);
      setGeneralMsg("Password changed successfully", 'success');
    } catch (e) {
      setGeneralMsg(e, 'error');
    } finally {
      setIsSendingNewPass(false);
    }
  }
 
  return (
    <div className="Profile-page">
      <div className="Profile-title">
        <h1>
          Profile <span>Page</span>
        </h1>
      </div>

      <div className="signup-page">
        <div className="signup-form">
          <label htmlFor="firstName">Name:</label>
          <div className="name-container">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={user.firstName}
              disabled
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={user.lastName}
              disabled
            />
          </div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={user.email}
            disabled
          />
          <div className="name-container">
            <button className="btn" onClick={() => setShowChangePassword(true)}>
              Change Password
            </button>
            <Link to="/contact" className="btn">
              Get in Touch
            </Link>
          </div>

          {showChangePassword && (
            <div
              className="forgot-password-modal inactive-user-backdrop"
              onClick={() => setShowChangePassword(false)}
            >
              <form
                className="forgot-password-content inactive-user-msg"
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleChangePass}
              >
                <button
                  className="close-btn"
                  onClick={() => setShowChangePassword(false)}
                  aria-label="Close forgot password modal"
                  type="button"
                >
                  &times;
                </button>
                <h3>Enter your old and new password</h3>
                <PasswordInput
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  visible={oldPasswordVisible}
                  toggleVisibility={() => setOldPasswordVisible((prev) => !prev)}
                  placeholder="Old Password"
                />
                <PasswordInput
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  visible={passwordVisible}
                  toggleVisibility={() => setPasswordVisible((prev) => !prev)}
                  placeholder="New Password"
                />
                <PasswordInput
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  visible={confirmPasswordVisible}
                  toggleVisibility={() => setConfirmPasswordVisible((prev) => !prev)}
                  placeholder="Confirm Password"
                />
                <button
                  className="send-reset-link-btn btn"
                  disabled={isSendingNewPass}
                  aria-label="Change password"
                  type="submit"
                >
                  {isSendingNewPass ? 'Changing...' : 'Change Password'}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;