import React from 'react';
import '../css/Home.css';

const Home = () => {

  return (
    <div className="home">
      <h1>Welcome!</h1>
      <p>You are now logged in and can use the LinknTrack Chrome extension to add private notes to your LinkedIn connections' profiles.</p>

      <p>
        Don’t have the extension yet? 
        <a className="color-blue" 
        href="https://chromewebstore.google.com/detail/linkntrack-extension/eiedlnmkmdpcjhaijngkcnmljgnofacd" 
        target="_blank" rel="noopener noreferrer"> Download it here</a>.
      </p>

      <div className="home-actions">
      </div>
    
    </div>
    
  );
};

export default Home;