import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {

  const location = useLocation();

  return (
    <footer className={location.pathname === '/landing' ? 'footer landing-bg-color' : 'footer'} role="contentinfo">
      <div className='footer-inner'>
        <p>
          &copy; 2024 LinknTrack. All rights reserved. 
        </p>
        <nav aria-label="Footer Navigation" className="footer-links">
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/contact">Contact Us</Link>
          <Link to="/about">About Us</Link>
          <a 
            href="https://chromewebstore.google.com/detail/linkntrack-notes-for-link/eiedlnmkmdpcjhaijngkcnmljgnofacd" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Chrome Extension, opens in a new tab"
          >
            Chrome Extension
          </a>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;