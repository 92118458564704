import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {FcGoogle} from 'react-icons/fc';
import { resendVerificationEmail, resendPasswordResetEmail } from '../services/authService';
import '../css/Auth.css';
import { Helmet } from 'react-helmet';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const setGeneralMsg = useGeneralMsgUpdate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isInactiveUser, setIsInactiveUser] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [isSendingResetLink, setIsSendingResetLink] = useState(false);
  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendCooldown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e)  => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(formData.email, formData.password);
      navigate('/home');
    } catch (error) {
      const errorMsg = error? error : 'An unknown error occurred';
      setGeneralMsg(errorMsg, 'error');
      if (errorMsg.includes('not verified')) {
        setIsInactiveUser(true); // Show the inactive user prompt
        setFormData({ ...formData, password: '' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    if (resendCooldown > 0) return;
    setResendCooldown(60);
    try {
      await resendVerificationEmail(formData.email);
      setGeneralMsg('Verification email sent. Please check your inbox.', 'success');
    } catch (error) {
      setGeneralMsg('Failed to resend email. Try again later.', 'error');
    }
  };

  const handleForgotPasswordSubmit = async () => {
    try {
      setIsSendingResetLink(true);
      await resendPasswordResetEmail(forgotPasswordEmail);
      setGeneralMsg('Password reset link sent to your email.', 'success');
      setForgotPasswordEmail('');
      setShowForgotPassword(false); // Close the modal after successful submission
    } catch (error) {
      setGeneralMsg( error || 'Failed to send reset link. Try again later.', 'error');
    } finally {
      setIsSendingResetLink(false);
    }
  };

  return (
    <>
      <Helmet>
      <title>Login - LinknTrack</title>
      <meta name="description" content="Login to your LinknTrack account to access your personalized LinkedIn connection notes." />
      <meta name="keywords" content="Login, LinknTrack, access account, LinkedIn, Chrome extension" />
      <meta property="og:title" content="Login - LinknTrack" />
      <meta property="og:description" content="Access your LinknTrack account and start managing your LinkedIn connections." />
      <meta property="og:url" content="https://linkntrack.com/login" />

      <meta name="twitter:title" content="Login - LinknTrack" />
      <meta name="twitter:description" content="Access your LinknTrack account and start managing your LinkedIn connections." />
      <meta name="twitter:url" content="https://linkntrack.com/login" /> 
    </Helmet>
    <div className='login-page'>
      <div className='login-title'>
        <h1>Welcome <span>Back!</span></h1>
        <p className='description'>Log in to access your LinkedIn notes and manage your connections.</p>
      </div>

      <div className="login-form-container">
        <form onSubmit={handleSubmit} className='login-form'>
            <input
              type="email"
              name="email"
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="password-input-container">
              <input
                type={passwordVisible ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setPasswordVisible(prevState => !prevState)} // Toggle password visibility
                aria-label="Toggle password visibility"
              >
                {!passwordVisible ? <FiEyeOff /> : <FiEye />} {/* Use icons instead of text */}
              </button>
            </div>
            <button
              type="button"
              className="forgot-password-btn"
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot Password?
            </button>

          <button className='login-button' type="submit" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>

          {isInactiveUser && (
            <div 
              className="inactive-user-backdrop" 
              onClick={() => setIsInactiveUser(false)} 
            >
              <div 
                className="inactive-user-msg" 
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the prompt
              >
                <button 
                  className="close-btn" 
                  onClick={() => setIsInactiveUser(false)}
                  aria-label="Close verification prompt"
                >
                  &times;
                </button>
                <p>Your email is not verified. Please check your inbox.</p>
                <button
                  className="resend-verification-btn"
                  onClick={handleResendVerification}
                  disabled={resendCooldown > 0}
                  aria-label="Resend verification email"
                >
                  {resendCooldown > 0 ? `${resendCooldown}s` : 'Resend Verification Email'}
                </button>
              </div>
            </div>
          )}

          <div className='or'>or</div>
          {/* sign in with google */}
          <a href={`${process.env.REACT_APP_BACKEND_API_URL}/auth/google`} aria-label={`Sign in with Google`} className='google-signin-btn login-button'> <FcGoogle />Sign in with Google</a>
          <p>Don't have an account? <Link to="/signup" className='color-blue underline'>Sign up</Link></p>
        </form>
      </div>
      {/* Forgot Password Modal */}
    {showForgotPassword && (
      <div className="forgot-password-modal inactive-user-backdrop" onClick={() => setShowForgotPassword(false)}>
        <div className="forgot-password-content inactive-user-msg" 
                onClick={(e) => e.stopPropagation()} >
          <button className="close-btn" onClick={() => setShowForgotPassword(false)} aria-label="Close forgot password modal">&times;</button>
          <h3>Enter your email to reset password</h3>
          <input
            type="email"
            placeholder="Your email"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            required
          />
          <button className="send-reset-link-btn btn" onClick={handleForgotPasswordSubmit} disabled={isSendingResetLink} aria-label="Send reset link">{isSendingResetLink ? 'Sending...' : 'Send Reset Link'}</button>
        </div>
      </div>
    )}
    </div>
    </>
  );
};

export default Login;