import { createContext, useContext, useEffect, useState } from 'react';
import { checkAuth, login as loginService, logout as logoutService } from '../services/authService';
import { useLoadingUpdate } from './LoadingContext';

const AuthContext = createContext();
const AuthUpdateContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const useAuthUpdate = () => {
    return useContext(AuthUpdateContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const setLoading = useLoadingUpdate();

    const initializeAuth = async () => {
        setLoading(true);
        try {
            const authenticatedUser = await checkAuth();
            
            setUser(authenticatedUser);
        } catch (error) {
            // console.error('Initialization error:', error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        initializeAuth();
    }, []);

    const login = async (email, password) => {
        try {
            await loginService(email, password);
            initializeAuth();
        } catch (error) {
            // console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async() => {
        try {
            await logoutService();
            setUser(null);
        } catch (error) {
            // console.error('Logout error:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};