import API from './api';

// Send a contact message
export const sendContactMessage = async (firstName, lastName, email, purpose, message) => {
  try {
    const response = await API.post('/contacts', {
      firstName,
      lastName,
      email,
      purpose,
      message,
    });
    return response.data;
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while sending the contact message.';
  }
};

// Fetch all contact messages (for admins)
export const getAllContactMessages = async (page = 1, limit = 10) => {
  try {
    const response = await API.get('/contacts', {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while fetching contact messages.';
  }
};

// Delete a contact message
export const deleteContactMessage = async (messageId) => {
  try {
    await API.delete(`/contacts/${messageId}`);
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while deleting the contact message.';
  }
};