import React from 'react';
import '../css/NotFound.css';
import emptyImg from '../assets/empty.svg';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src={emptyImg} alt="Empty" className='empty-img' />
      <div className='not-found-container-title'>
        <h1>404 - Page Not <span>Found</span></h1>
        <p>Oops! We can't seem to find the page you're looking for. It looks like this page might be missing or moved. You can <Link to="/landing" className='color-blue'>go back to the landing page</Link> or <Link to="/login" className='color-blue'>log in</Link> to access your account.</p>
      </div>
    </div>
  );
};

export default NotFound;