import React from 'react';
import '../css/Landing.css';
import { Link } from 'react-router-dom';
import Faq from "react-faq-component";

const data = {
  rows: [
      {
          title: "What is LinknTrack?",
          content: <p className='faq-content'>LinknTrack is a Chrome extension designed to help you add, organize, and manage notes for your LinkedIn connections. It allows you to capture key details about your network, set reminders, and stay organized, ensuring you never forget important follow-ups or conversations.</p>,
      },
      {
          title: "Is my data private and secure?",
          content: <p className='faq-content'>Yes, your notes are securely stored and synced. LinknTrack ensures that your data remains private, accessible only to you, and integrated directly into your LinkedIn experience.</p>,
      },
      {
          title: "Can I access my notes from multiple devices?",
          content: <p className='faq-content'>Yes! LinknTrack syncs your notes across all devices where you’ve installed the extension. You can access, edit, and manage your notes from any device with the Chrome browser.</p>,
      },
      {
        title: "Is there a mobile version of LinknTrack?",
        content: <p className='faq-content'>Currently, LinknTrack is designed as a Chrome extension. It works seamlessly on desktop browsers, but we are working on website central dashboard that will allow you to access your notes from any device.</p>,
    },
  ],
};

const styles = {
  bgColor: '#1B1F23',
  titleTextColor: "fdfdfd",
  rowTitleColor: "#fdfdfd",
  rowContentColor: '#fdfdfd',
  arrowColor: "#fdfdfd",
};

const Landing = () => {
  return (
    <main className="landing-page">
      <section className='landing-bg-color'>
        <div className='landing-page-section hero-section'>
          <div className=' hero-txt'>
            <h1>Be the Professional Who Never Forgets</h1>
            <p>Easily add private notes for each LinkedIn connection, categorize them by relevance, and set reminders to ensure timely follow-ups and stronger networking</p>
            <div className='btn-container'>
            <a className='btn' href='https://chromewebstore.google.com/detail/linkntrack-notes-for-link/eiedlnmkmdpcjhaijngkcnmljgnofacd' target='_blank' rel='noopener noreferrer' aria-label="Install LinknTrack now">Install LinknTrack Now!</a>
            <p>Be from the first 1,000 <br/> 100+ already joined!</p>
            </div>
          </div>
          <div className="hero-img" alt="Illustration"></div>
        </div>
      </section>
      {/* <section className='features-section'>
        <div className='feature-container'>
          <h4><PiNotePencilLight className='icon'/> Add Notes Instantly</h4>
          <p>Quickly save personalized notes directly on LinkedIn profiles to remember key details, conversations, and follow-ups.</p>
        </div>
        <div className='feature-container'>
          <h4><FaRegFolder className='icon'/> Organize with Ease</h4>
          <p>Group your notes with tailored titles with distinct colors for better organization or just add your own.</p>
        </div>
        <div className='feature-container'>
          <h4><MdAlarm className='icon'/> Never Miss a Follow-Up</h4>
          <p>Set reminders for your notes and receive email notifications when it’s time to reconnect or follow up.</p>
        </div>
      </section> */}
      <section className='demo-section landing-page-section'>
        <h2>See LinknTrack in Action!</h2>
        <p>Watch how LinknTrack makes networking on LinkedIn seamless. Learn to add notes, set reminders, and stay organized—all in just a few clicks!</p>
        <iframe 
          className='demo-vid' 
          title='LinknTrack Demo' 
          src="https://www.youtube.com/embed/0EW8vnvfC7E?si=NHcq6Vzjhfu4vIFN" 
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </section>
      <section className='mid-cta-section landing-page-section'>
        <div className='store-img'></div>
        <div className='mid-cta-txt'>
          <h2>What are you waiting for?</h2>
          <a className='btn' href='https://chromewebstore.google.com/detail/linkntrack-notes-for-link/eiedlnmkmdpcjhaijngkcnmljgnofacd' target='_blank' rel='noopener noreferrer' aria-label="Install LinknTrack now">Get LinknTrack today!</a>
        </div>
      </section>
      <section className=''>
        <div className='landing-page-section extension-imgs-section'>
          <h2>Explore the key features of the extension</h2>
          <img src='./images/extension2.webp' alt='Extension 1'></img>
          <img src='./images/extension1.webp' alt='Extension 2'></img>
        </div>
      </section>
      <section className='faq-section landing-page-section'>
        <h2>Frequently Asked Questions</h2>
        <p>Got questions? We've got answers! Learn more about how LinknTrack works, its features, and more. If you have any other questions, feel free to <Link to="/contact" className='color-blue underline'>contact us</Link></p>
        <Faq className='faq' data={data} styles={styles} />
      </section>
      <section className='landing-bg-color'>
        <div className='landing-page-section last-cta-section'>
          <h2>Take Notes, Set Reminders, Connect Better</h2>
          <a className='btn' href='https://chromewebstore.google.com/detail/linkntrack-notes-for-link/eiedlnmkmdpcjhaijngkcnmljgnofacd' target='_blank' rel='noopener noreferrer' aria-label="Install LinknTrack now">Install LinknTrack Now!</a>
        </div>
      </section>
    </main>
  );
};

export default Landing;