import React, { useState } from 'react';
import '../css/Contact.css';
import { MdMailOutline } from "react-icons/md";
import { RxLinkedinLogo } from "react-icons/rx";
import { BsTwitterX } from "react-icons/bs";
import { sendContactMessage } from '../services/contactService';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const setGeneralMsg = useGeneralMsgUpdate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    purpose: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable button and show "sending" state
    
    try {
      await sendContactMessage(formData.firstName, formData.lastName, formData.email, formData.purpose, formData.message);
      setGeneralMsg('Message sent successfully!', 'success');
      setFormData({ firstName: '', lastName: '', email: '', purpose: '', message: '' }); // Clear form
      setIsSubmitting(false); // Reset submitting state
    } catch (error) {
      setGeneralMsg('Failed to send message. Please try again.', 'error');
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - LinknTrack</title>
        <meta name="description" content="Get in touch with the LinknTrack team for support or inquiries regarding our Chrome extension." />
        <meta name="keywords" content="Contact, LinknTrack, support, help, LinkedIn, Chrome extension" />
        <meta property="og:title" content="Contact Us - LinknTrack" />
        <meta property="og:description" content="Reach out to LinknTrack for assistance or more information about our LinkedIn management tools." />
        <meta property="og:url" content="https://linkntrack.com/contact" />

        <meta name="twitter:title" content="Contact Us - LinknTrack" />
        <meta name="twitter:description" content="Reach out to LinknTrack for assistance or more information about our LinkedIn management tools." />
        <meta name="twitter:url" content="https://linkntrack.com/contact" />

      </Helmet>
      <div className='contact-page'>
      <div className='contact-title'>
        <h1>Get in <span>touch</span></h1>
        <p>We'd love to hear from you! Whether you have a question, feedback, or a collaboration idea, feel free to contact us</p>
      </div>
      <div className='contact-container landing-bg-color'>
        <form className='contact-form' onSubmit={handleSubmit}>
          <div className='contact-form-title'>
            <h2>Let Us Know How We Can Assist You!</h2>
            <p> Fill out the form below, and we’ll get back to you as soon as possible with any assistance you need</p>
          </div>
          
          <div className='contact-form-content'>
            <div className='names-container'>
              <input
                  type="text"
                  placeholder="First Name"
                  required
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  required
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
            </div>

            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            
            <select
              id="purpose"
              name="purpose"
              required
              value={formData.purpose}
              onChange={handleInputChange}
              aria-label="Purpose of Contact"
            >
              <option value="" disabled selected>Purpose of Contact</option>
              <option value="support">Technical Support</option>
              <option value="feature">Feature Request</option>
              <option value="feedback">Feedback</option>
              <option value="collaboration">Collaboration Inquiry</option>
              <option value="general">General Inquiry</option>
            </select>

            <textarea
              placeholder="Message"
              rows={10}
              required
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>

        <div className='contact-social-container'>
          <div className='contact-social-title'>
            <h2>Contact Us via Email & Social</h2>
            <p>Feel free to connect with us via email or follow us on social media. We're always here to help and would love to hear from you</p>
          </div>
          <div className='contact-social'>
            <MdMailOutline className='icon'/> 
            <a className='email' href="mailto:team@linkntrack.com" aria-label="Email us at team@linkntrack.com">team@linkntrack.com</a>
          </div>
          <div className='contact-social'>
            <span>Julien Barbier: </span> 
            <a href='https://linkedin.com/in/julienbarbier' target='_blank' rel='noopener noreferrer' aria-label="Julien Barbier LinkedIn profile">
              <RxLinkedinLogo className='icon'/>
            </a> 
            <a href='https://x.com/julienbarbier42' target='_blank' rel='noopener noreferrer' aria-label="Julien Barbier Twitter profile">
              <BsTwitterX className='icon'/>
            </a> 
          </div>
          <div className='contact-social'>
            <span>Mariem Ehab: </span> 
            <a href='https://linkedin.com/in/mariem-ehab-1431301b3' target='_blank' rel='noopener noreferrer' aria-label="Mariem Ehab LinkedIn profile">
              <RxLinkedinLogo className='icon'/>
            </a> 
            <a href='https://x.com/_MariemEhab' target='_blank' rel='noopener noreferrer' aria-label="Mariem Ehab Twitter profile">
              <BsTwitterX className='icon'/>
            </a> 
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Contact;