import React from 'react'
import '../css/About.css'
import julien from '../assets/julien.svg'
import mariem from '../assets/mariem.svg'
import { RxLinkedinLogo } from "react-icons/rx";
import { BsTwitterX } from "react-icons/bs";
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <>
      <Helmet>
      <title>About Us - LinknTrack</title>
      <meta name="description" content="Learn more about LinknTrack and our mission to help you manage and grow your LinkedIn connections." />
      <meta name="keywords" content="About, LinknTrack, mission, team, LinkedIn, Chrome extension" />
      <meta property="og:title" content="About Us - LinknTrack" />
      <meta property="og:description" content="Discover the story behind LinknTrack and how we help you track and personalize your LinkedIn connections." />
      <meta property="og:url" content="https://linkntrack.com/about" />

      <meta name="twitter:title" content="About Us - LinknTrack" />
      <meta name="twitter:description" content="Discover the story behind LinknTrack and how we help you track and personalize your LinkedIn connections." />
      <meta name="twitter:url" content="https://linkntrack.com/about" />
    </Helmet>

    <div className='about-page'>
      <div className='about-title'>
        <h1>About <span>Us</span></h1>
        <p>Learn more about the team behind LinknTrack and the Ship or Sink program that helped bring this project to life</p>
      </div>

      <div className='ship-or-sink'>
        <div className='ship-or-sink-cta'>
          <h2>Ship or Sink</h2>
          <p>Focus. Ship. Win.</p>
          <a className='register-btn btn' href='http://shiporsink.net' target='_blank' rel='noopener noreferrer'>Register</a>
        </div>

        <p className='ship-or-sink-info'>
        This project is part of the Ship or Sink program, an exclusive 1-on-1 mentorship opportunity for ALX and Holberton graduates with Julien Barbier, Founder of Holberton School and Chief Product Officer at ALX.
        Over the course of three months, Julien will offer his time and expertise to help participants transform their ideas into potential startups or even large companies. During this mentorship, Julien will temporarily assume the role of CEO, sharing his vast experience and guiding the team toward success—all completely FREE.
        </p>
      </div>

      <div className='team-members-container'>
        <h2>Meet Our Team</h2>

        <div className='team-members'>
            <div className='team-member'>
                    <img className='member-img' src={mariem} alt='Team Member'/>
                <div className='member-info'>
                    <div className='member-title'>
                        <h3>Mariem Ehab</h3>
                        <div className='social-icons'>
                            <a href='https://linkedin.com/in/mariem-ehab-1431301b3' target='_blank' rel='noopener noreferrer' aria-label="Mariem Ehab LinkedIn profile">
                                <RxLinkedinLogo className='icon'/>
                            </a> 
                            <a href='https://x.com/_MariemEhab' target='_blank' rel='noopener noreferrer' aria-label="Mariem Ehab Twitter profile">
                                <BsTwitterX className='icon'/>
                            </a> 
                        </div>
                    </div>
                    <p>A Computer Engineering graduate and a certified Software Engineer from ALX (Cohort 19) with extensive hands-on experience in full-stack development. Played a pivotal role in all aspects of this project, including designing the overall architecture and user experience, developing a Chrome extension to enhance functionality, implementing frontend features for a seamless user interface, and building and optimizing backend systems for performance and scalability.</p>
                    </div>
            </div>
            <div className='team-member'>
                    <img className='member-img' src={julien} alt='Team Member'/>
                <div className='member-info'>
                <div className='member-title'>
                        <h3>Julien Barbier</h3>
                        <div className='social-icons'>
                            <a href='https://linkedin.com/in/julienbarbier' target='_blank' rel='noopener noreferrer' aria-label="Julien Barbier LinkedIn profile">
                                <RxLinkedinLogo className='icon'/>
                            </a> 
                            <a href='https://x.com/julienbarbier42' target='_blank' rel='noopener noreferrer' aria-label="Julien Barbier Twitter profile">
                                <BsTwitterX className='icon'/>
                            </a>
                        </div>
                    </div>
                    <p>Founder of Holberton School and Chief Product Officer of ALX Programs. Acted as the CPO for the project, with all aspects of the project being guided and mentored under his leadership. He provided valuable insights on how to create and scale startups, emphasizing the importance of focus and shipping.</p>
                </div>
            </div>
        </div>
      </div>

    </div>
    </>
  )
}

export default About
