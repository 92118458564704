import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';

const Navbar = () => {
  const { logout, user } = useAuth();
  const setGeneralMsg = useGeneralMsgUpdate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const location = useLocation();

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await logout();
    } catch (error) {
      const errorMsg = error || 'An unknown error occurred';
      setGeneralMsg(errorMsg, 'error');
    } finally {
      setIsLoggingOut(false);
      setGeneralMsg('Logout successful', 'success');
    }
  };

  // Check if on auth pages or landing page
  const isAuthPage = location.pathname === '/login' || location.pathname === '/signup';
  const isLandingPage = location.pathname === '/landing';
  const isHomePage = location.pathname === '/home';

  return (
    <nav 
      className={`navbar ${isLandingPage ? 'landing-bg-color' : ''}`}
      aria-label="Main navigation"
    >
      <div className='navbar-inner'>
        <Link to="/" aria-label="Landing page">
          <p>
            <span className="nav-l">L</span>ink<span className="nav-n">n</span>Track
          </p>
        </Link>
        <div className="nav-links">
          { user && (
              !isHomePage ? (
                <Link to="/home" className="home-link" aria-label="Homepage link">
                  Homepage
                </Link>
              ) : (
                <Link to="/profile" className="profile-link" aria-label="Profile link">
                    Profile
                  </Link>
              )
            )
          }

        { user ? (
            <button
              className="logout-btn"
              onClick={handleLogout}
              disabled={isLoggingOut}
              aria-busy={isLoggingOut}
              aria-label={isLoggingOut ? 'Logging out' : 'Logout button'}
              >
              {isLoggingOut ? 'Logging out...' : 'Logout'}
            </button>
          ) : !isAuthPage && (
              <>
                <Link to="/login" className="login-btn" aria-label="Login link">
                  Login
                </Link>
                <Link to="/signup" className="signup-btn" aria-label="Signup link">
                  Register
                </Link>
              </>
            )
        }
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
