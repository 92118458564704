import API from './api';

// Login user
export const login = async (email, password) => {
  try {
    const response = await API.post('/auth/login', { email, password });
    return response.data; // No need to handle token directly
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred during login.';
  }
};

// Logout user
export const logout = async () => {
  try {
    await API.post('/auth/logout'); // Backend should clear the token cookie
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred during Logout.';
  }
};

// Register user
export const register = async (firstName, lastName, email, password) => {
  try {
    await API.post('/auth/register', { firstName, lastName, email, password });
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred during registration.';
  }
};

// Check authentication status
export const checkAuth = async () => {
  try {
    const response = await API.get('/users/me'); // Backend determines auth status
    return response.data;
  } catch (error) {
    return null;
  }
};

// Resend verification email
export const resendVerificationEmail = async (email) => {
  try {
    const response = await API.post('/auth/resend-verification-email', { email });
    return response.data;
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while resending the verification email.';
  }
};

export const fetchAnalytics = async () => {
  try {
    const response = await API.get('/admin/analytics');
    return response.data.analytics; // Corrected the return value
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while fetching analytics data.';
  }
};

export const resendPasswordResetEmail = async (email) => {
  try {
    const response = await API.post('/auth/forget-password', { email });
    return response.data;
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while sending the reset password email.';
  }
};

// Reset user password
export const resetPassword = async (token, newPassword) => {
  try {
    const response = await API.post(`/auth/reset-password`, { token, newPassword });
    return response.data; // Expect a success message or confirmation
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while resetting the password.';
  }
};

// Validate reset password token
export const validateResetToken = async (token) => {
  try {
    const response = await API.get(`/auth/validate-reset-token/${token}`);
    return response.data; // Expect a success message
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'Invalid or expired reset token.';
  }
};

// Change user password
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await API.put('/users/change-password', { oldPassword, newPassword });
    return response.data; // Expect a success message
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred while changing the password.';
  }
};