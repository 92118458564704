import React, { useState, useEffect } from 'react';
import '../css/Auth.css';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword, validateResetToken } from '../services/authService';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import { useLoadingUpdate } from '../context/LoadingContext';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const PasswordReset = () => {
  const navigate = useNavigate();
  const { token } = useParams(); // Capture the token from URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const setIsLoading = useLoadingUpdate();
  const setGeneralMsg = useGeneralMsgUpdate();

  useEffect(() => {
    const validateToken = async () => {
      setIsLoading(true);
      try {
        await validateResetToken(token);
      } catch (err) {
        setGeneralMsg(err.response?.data?.message || 'Invalid or expired reset token.', 'error');
        navigate('/login'); // Redirect to login on failure
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, [token]);

  const validateInputs = () => {

      if (newPassword.length < 8) return 'Password must be at least 8 characters long';
      if (!/[A-Z]/.test(newPassword)) return 'Password must have at least one uppercase letter';
      if (!/[a-z]/.test(newPassword)) return 'Password must have at least one lowercase letter';

    if (newPassword !== confirmPassword) {
      return 'Passwords do not match';
    }

    return null;

  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    const error = validateInputs();

    if (error) {
      setGeneralMsg(error, 'error');
      return;
    }

    setIsLoading(true);
    try {
      await resetPassword(token, newPassword);
      setGeneralMsg('Password successfully updated.', 'success');
      navigate('/login'); // Redirect to login after password reset
    } catch (err) {
        setGeneralMsg(err.response?.data?.message || 'Error resetting password.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="password-reset-container login-page">
      <div className='login-title'>
        <h1>Reset Your <span>Password</span></h1>
      </div>
      <div className="login-form-container">
        <form className='login-form' onSubmit={handlePasswordReset}>
          <div className="password-input-container">
            <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
            />
            <button
                type="button"
                className="password-toggle"
                onClick={() => setPasswordVisible(prevState => !prevState)} // Toggle password visibility
                aria-label="Toggle password visibility"
                >
                {!passwordVisible ? <FiEyeOff /> : <FiEye />} {/* Use icons instead of text */}
            </button>
          </div>
           
            <div className="password-input-container">
                <input
                    type={confirmPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <button
                    type="button"
                    className="password-toggle"
                    onClick={() => setConfirmPasswordVisible(prevState => !prevState)} // Toggle password visibility
                    aria-label="Toggle password visibility"
                    >
                    {!confirmPasswordVisible ? <FiEyeOff /> : <FiEye />} {/* Use icons instead of text */}
                </button>
            </div>
            
            <button
                className='btn'
                type='submit'
            >
                Reset Password
            </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
