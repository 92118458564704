import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './css/App.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Landing from './pages/Landing';
// import PreLaunch from './pages/PreLaunch';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Navbar from './components/Navbar';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';  
import AdminDashboard from './pages/AdminDashboard';
import Contact from './pages/Contact';
import About from './pages/About';
import ScrollToTop from './components/ScrollToTop';
import PasswordReset from './pages/PasswordReset'; 
import Profile from './pages/Profile';

const App = () => {
  return (
    <div className="App dark-mode">
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        {/* <Route 
          path="/pre-launch"
          element={<PublicRoute component={PreLaunch} />} 
        /> */}
        <Route path="/" element={<Navigate to="/landing" />} />
        <Route 
          path="/landing"
          element={<PublicRoute component={Landing} />} 
        />
                <Route 
          path="/privacy"
          element={<PublicRoute component={Privacy} />} 
        />
        <Route 
          path="/contact"
          element={<PublicRoute component={Contact} />} 
        />

        <Route 
          path="/about"
          element={<PublicRoute component={About} />} 
        />
        
        <Route 
          path="/login" 
          element={<PublicRoute component={Login} restricted />} 
        />
        <Route 
          path="/signup" 
          element={<PublicRoute component={Signup} restricted />} 
        />
        <Route 
          path="/home" 
          element={<PrivateRoute component={Home} />} 
        />
        <Route 
          path="/profile" 
          element={<PrivateRoute component={Profile} />} 
        />
        <Route 
          path="/admin/analytics" 
          element={<AdminDashboard />} 
        />
          <Route 
            path="/reset-password/:token" 
            element={<PublicRoute component={PasswordReset} />} 
          />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} /> {/* This handles any unmatched paths */}
      </Routes>
      <Footer />
    </Router>
    </div>
  );
};

export default App;